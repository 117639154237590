import type { IComponentPhoneField } from '~/typings/types/component.types';

/**
 * @description Get phone data and format it in two ways: html and normalized
 * @param data<IComponentPhoneField>
 * @returns {{normalizedPhone: ComputedRef<string>, htmlPhone: ComputedRef<string>}}
 */
export default function usePhone(data: IComponentPhoneField) {
  const htmlPhone = computed(() => {
    return data.country.iso + data.region + data.number.join('');
  });

  const normalizedPhone = computed(() => {
    return data.country.national + ' (' + data.region + ') ' + data.number.join('-');
  });

  return {
    htmlPhone,
    normalizedPhone,
  };
}
