import type { JSONSchemaType } from 'ajv';
import type { IComponentPhoneField } from '~/typings/types/component.types';

export const strictPhoneSchema: JSONSchemaType<IComponentPhoneField> = {
  type: 'object',
  properties: {
    country: {
      type: 'object',
      properties: {
        national: { type: 'string' },
        iso: { type: 'string' },
      },
      required: ['national', 'iso'],
      additionalProperties: false,
    },
    region: { type: 'string' },
    number: { type: 'array', items: { type: 'string' } },
  },
  required: ['country', 'region', 'number'],
  additionalProperties: false,
};

export const nullablePhoneSchema: JSONSchemaType<IComponentPhoneField> = {
  type: 'object',
  nullable: true,
  properties: {
    country: {
      type: 'object',
      properties: {
        national: { type: 'string' },
        iso: { type: 'string' },
      },
      required: ['national', 'iso'],
      additionalProperties: false,
    },
    region: { type: 'string' },
    number: { type: 'array', items: { type: 'string' } },
  },
  required: ['country', 'region', 'number'],
  additionalProperties: false,
};
